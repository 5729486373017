<template>
  <div class="w-100">
    <b-row class="mt-2">
      <b-col
        md="12"
      >
        <b-button
          variant="outline-secondary"
          class="btn-icon rounded-circle float-left"
          style="font-weight: 700; width: 30px; height: 30px; padding-top: 7px;"
        >
          1
        </b-button>
        <h5
          class="float-left pl-1 pt-50"
          style="font-weight: 700; font-size: 16px; color: #A7AAB9 !important;"
        >
          Install SEOcloud script on your site
        </h5>

        <b-card
          no-body
          class="overflow-hidden w-100"
        >
          <b-row
            no-gutters
            class="mt-1 ml-3 row-first-step-claim-btns"
          >
            <b-col
              md="5"
              sm="3"
              :class="`card-config-claim cards w-100 type-active mr-50 ${planActive === '0' || planActive === 0 ? 'type-active' : 'type-inactive'}`"
              style="height: 85px;"
            >
              <label
                class="w-100"
                style="height: 85px;"
                role="button"
              >
                <input
                  v-model="planActive"
                  type="radio"
                  value="0"
                  checked
                  style="float: left; margin-top: 15px; display: none;"
                >
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p
                    class="color-text-dark mb-0"
                    style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                  >
                    <b-img-lazy
                      fluid
                      :src="sitesImg"
                      alt="no sites img"
                      style="width: 61px; height: 40px;"
                      loading="lazy"
                    />
                  </p>
                  <b-list-group
                    class="list-group-circle mb-50 mt-50"
                    style="width: 100% !important; display: block; text-align: center;"
                  >
                    <span
                      :style="`padding-bottom: 0px; ${planActive === '0' || planActive === 0 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                    >
                      For any site
                    </span>
                  </b-list-group>
                </div>
              </label>
            </b-col>
            <b-col
              md="5"
              sm="3"
              :class="`card-config-claim cards w-100 ml-50 ${planActive === '1' || planActive === 1 ? 'type-active' : 'type-inactive'}`"
              style="height: 85px;"
            >
              <label
                class="w-100"
                style="height: 85px;"
                role="button"
              >
                <input
                  v-model="planActive"
                  type="radio"
                  value="1"
                  checked
                  style="float: left; margin-top: 15px; display: none;"
                >
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p
                    class="color-text-dark mb-0"
                    style="color: #181E38 !important; padding-top: 10px; font-size: 15px; font-weight: bold; text-align: center;"
                  >
                    <b-img-lazy
                      fluid
                      :src="siteWordp"
                      alt="no sites img"
                      style="width: 40px; height: 40px;"
                      loading="lazy"
                    />
                  </p>
                  <b-list-group
                    class="list-group-circle mb-50 mt-50"
                    style="width: 100% !important; display: block; text-align: center;"
                  >
                    <span
                      :style="`padding-bottom: 0px; ${planActive === '1' || planActive === 1 ? 'color: #7838FF !important; background: rgb(243, 238, 255) !important;' : 'color: #656B85 !important; background: #fff !important;'}`"
                    >
                      For WordPress Site
                    </span>
                  </b-list-group>
                </div>
              </label>
            </b-col>
          </b-row>
          <b-row
            no-gutters
            class="mt-1 ml-3 row-first-step-claim"
          >
            <b-col
              v-if="planActive === '0' || planActive === 0"
              md="12"
              class="mt-1"
            >
              <p
                class=""
                style="font-weight: 700;"
              >
                1.1 <span class=" ml-1">Paste this script in {{ txtHead }}</span>
              </p>
              <div
                class="form-control wizard-form-control wizard-form-control-mobile-script d-flex align-items-center testing-code px-20px mb-10px"
                style="background: #F0F1F3; height: 71px; color: #656B85; font-size: 14px;"
              >
                <span class="code text-red">
                  &lt;script src="https://cdn.seocloud.ai/{{keyID}}" type="text/javascript"&gt;&lt;/script&gt;
                </span>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  style="color: #A7AAB9;"
                  class="btn-icon rounded-circle"
                  @click.stop.prevent="copyTestingCode1"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
                <input
                  id="testing-code1"
                  type="hidden"
                  value="<script src=&quot;https://secloud.io/scripts/&quot; type=&quot;text/javascript&quot; defer=&quot;defer&quot;></script>"
                >
              </div>
            </b-col>
            <b-col
              v-else
              md="12"
              class="mt-1"
            >
              <p
                class=""
                style="font-weight: 700;"
              >1.1
                <span class=" ml-1">Download and install this </span>
                <b-link
                  class=""
                  style="font-size: 16px; text-decoration: underline; color: #7838ff;"
                >
                  <small>plugin</small>
                </b-link>
              </p>
              <p
                class=""
                style="font-weight: 700;"
              >
                1.2 <span class=" ml-1">Register with Key</span>
              </p>
              <div
                class="form-control wizard-form-control d-flex align-items-center testing-code px-20px mb-10px"
                style="background: #F0F1F3; color: #656B85; font-size: 14px;"
              >
                <span
                  v-if="!keyVisible"
                  class="code text-red"
                >••••••••{{ keySite.length === 21 ? keySite.slice(19) : keySite.slice(20) }}</span>
                <span
                  v-else
                  class="code text-red"
                >{{ keyID }}</span>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  style="color: #A7AAB9;"
                  class="btn-icon rounded-circle ml-1"
                  @click.prevent="toggleVisibleKey"
                >
                  <feather-icon icon="EyeIcon" v-if="!keyVisible" />
                  <feather-icon icon="EyeOffIcon" v-if="keyVisible" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  style="color: #A7AAB9;"
                  class="btn-icon rounded-circle ml-0"
                  @click.stop.prevent="copyTestingCode2"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
                <input
                  id="testing-code2"
                  type="hidden"
                  :value="keySite ? keySite : &quot;12561294222479838734&quot;"
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        md="12"
      >
        <b-button
          variant="outline-secondary"
          class="btn-icon rounded-circle float-left"
          style="font-weight: 700; width: 30px; height: 30px; padding-top: 7px;"
        >
          2
        </b-button>
        <h5
          class="float-left pl-1 pt-50"
          style="font-weight: 700; font-size: 16px; color: #A7AAB9 !important;"
        >
          Site verification
          <b-button
            v-if="counterTriesVerify >= 1 && !processVerificationSuccess"
            variant="outline-secondary"
            :class="verifying ? 'btn-icon ml-50 cursor-auto' : 'btn-icon ml-50 cursor-pointer'"
            :disabled="verifying ? true : false"
            style="height: 35px !important; width: 40px !important; min-width: 30px !important; padding-top: 7px !important;"
            @click.prevent="verifyFunction"
          >
            <b-img-lazy
              fluid
              :src="refreshIcon"
              alt="refresh"
              width="18"
              height="22"
              :class="verifying ? 'refreshIcon' : ''"
              loading="lazy"
            />
          </b-button>
        </h5>

        <b-card
          no-body
          class="overflow-hidden w-100 mb-1"
        >
          <b-row
            no-gutters
            class="mt-1 ml-3"
          >
            <b-button
              :style="`${counterTriesVerify >= 1 && counterTriesVerify < 3 ? 'background: rgba(183, 183, 183, 0.18) !important; border: 1px solid rgb(172, 172, 172) !important; width: 155px; height: 33px;' : processVerificationSuccess ? 'background: rgba(113, 215, 43, 0.18) !important; border: 1px solid rgb(154, 255, 52) !important; width: 90px; height: 33px;' : verifying ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 90px; height: 33px;' : 'background: #CA34FF2E !important; border: 1px solid #7838ff !important; width: 90px; height: 33px;'}`"
              class="p-0"
              variant="primary"
              :disabled="verifying || (counterTriesVerify >= 1 && !processVerificationSuccess)"
              @click.prevent="verifyFunction"
            >
              <span
                class=""
                :style="counterTriesVerify >= 1 && counterTriesVerify < 3 ? 'color: rgb(72, 72, 72) !important; font-weight: 700;' : processVerificationSuccess ? 'color: #306016 !important; font-weight: 700;' : verifying ? 'color: #FFFFFF; font-weight: 700;' : 'color: #7838ff; font-weight: 700;'"
              >{{ counterTriesVerify >= 3 ? 'Timeout' : counterTriesVerify >= 1 && counterTriesVerify < 3 ? 'Script not found' : processVerificationSuccess ? 'Verified' : 'Verify' }}</span>
              <b-spinner
                v-if="verifying"
                :variant="counterTriesVerify >= 1 && counterTriesVerify < 3 ? 'dark' : 'light'"
                small
                class="ml-50"
              />
              <feather-icon
                v-if="!verifying && processVerificationSuccess"
                icon="CheckIcon"
                class="ml-50"
                :style="processVerificationSuccess ? 'color: #306016 !important;' : 'color: #7838ff;'"
              />
            </b-button>
          </b-row>
          <b-alert
            v-if="processVerificationFail"
            show
            variant="primary"
            class="mt-1"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="XIcon"
                role="button"
                @click.stop.prevent="processVerificationFail = false"
              />
              <span class="ml-25" v-if="counterTriesVerify >= 1 && counterTriesVerify < 3"><b>Script not found: </b>Your verification was not succesful, please repeat step one.</span>
              <span class="ml-25" v-else-if="counterTriesVerify >= 3"><b>Timeout: </b>Your verification was not succesful, please repeat step one.</span>
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BImgLazy, BCard, BCardBody, BCardText, BCardImgLazy, BFormTextarea, BListGroup, BListGroupItem, BFormInput, BLink, BSpinner, BAlert,
  BOverlay, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import verify from 'jsonwebtoken/verify'
import { db, auth, firebase, dbErrors } from '@/firebase'

export default {
  name: 'ClaimOwnership',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BRow,
    BCol,
    BImgLazy,
    BCard,
    BCardBody,
    BCardText,
    BCardImgLazy,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BLink,
    BSpinner,
    BAlert,
    BOverlay,
    BAvatar,
  },
  props: ['name', 'url', 'keySite', 'img', 'siteProp', 'claimLicense', 'showOpen', 'typeOwnership'],
  data() {
    return {
      siteImg: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteWordp: require('@/assets/images/illustration/i-wordpress.png'),
      refreshIcon: require('@/assets/images/icons/i-refesh.svg'),
      planActive: 0,
      txtHead: '<head>',
      verifying: false,
      processVerificationFail: false,
      processVerificationSuccess: false,
      overlayImgSite: true,
      processImg: false,
      failAvatarLoad: false,
      keyID: '',
      counterTriesVerify: 0,
      keyVisible: false
    }
  },
  watch: {
    showOpen(val) {
      // console.log(val)
      this.processVerificationFail = false
      this.processVerificationSuccess = false
      this.counterTriesVerify = 0

      if(val && this.$route.params.id) {
        this.counterTriesVerify = 0
        const user = auth.currentUser
        if(user) {
          this.keyVisible = false

          // console.log(val, this.$route.params.id, this.$route.params.project)
          const getFirstUser = user.uid.slice(0, 4);
          const getFirstSite = this.$route.params.id.slice(0, 4);
          // const getFirstProject = this.$route.params.project.slice(0, 3);
          this.keyID = getFirstUser + getFirstSite
          setTimeout(() => {
            // console.log('object',this.typeOwnership);
            if(this.typeOwnership === false) {
              this.verifyFunction()
            }
          }, 2000)
        }
      }
    },
    url(val) {
      // console.log(val)
      if(val && this.$route.params.id) {
        this.counterTriesVerify = 0
        const user = auth.currentUser
        if(user) {
          this.keyVisible = false

          // console.log(val, this.$route.params.id, this.$route.params.project)
          const getFirstUser = user.uid.slice(0, 4);
          const getFirstSite = this.$route.params.id.slice(0, 4);
          // const getFirstProject = this.$route.params.project.slice(0, 3);
          this.keyID = getFirstUser + getFirstSite
        }
        // if(val) {
        //   this.overlayImgSite = true
        //   db.collection('sites').where('url', '==', val).where('name', '==', this.name).get()
        //   .then(sitesQuery => {
        //     let counter = 0
        //     if (sitesQuery.size >= 1) {
        //       sitesQuery.forEach(site => {
        //         counter += 1
        //         if (counter === 1) {
        //           this.siteImg = site.data().img
        //         }
        //       })
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })

        //   setTimeout(() => {
        //     this.overlayImgSite = false
        //   }, 500)
        //   this.processVerificationSuccess = false
        // }
      }
    },
    processImg() {
      this.overlayImgSite = false
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    avatarFail() {
      // console.log('first')
      this.failAvatarLoad = true
    },
    toggleVisibleKey() {
      this.keyVisible ? this.keyVisible = false : this.keyVisible = true
    },
    verifyFunction() {
      this.verifying = true

      var obj = {
        "url": this.url,
        "scriptSrc": `https://cdn.seocloud.ai/${this.keyID}`
      }
      // console.log(obj)

      var config = {
        method: 'POST',
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(obj),
      }

      this.axios(config)
      .then(response => {
        console.log(response.data)
        if(!response.data.scriptPresent) {
          this.counterTriesVerify >= 3 ? this.counterTriesVerify = 1 : this.counterTriesVerify += 1
          this.verifying = false
          this.processVerificationFail = true
        } else {
          this.processVerificationFail = false
          this.counterTriesVerify = 0
          console.log('exist file in html')
          setTimeout(() => {
            this.verifying = false
            // this.processVerificationFail = true
            this.processVerificationSuccess = true
            localStorage.setItem('verify', 'active')

            // if(this.keySite) {
            //   const user = auth.currentUser
            //   if(user) {
            //     db.collection('Sites').doc(this.keySite).get()
            //     .then(siteDoc => {
            //       db.collection('Users').doc(user.uid).get()
            //       .then(userDoc => {
            //         var arrayLastOwner = siteDoc.data().lastOwner && siteDoc.data().lastOwner.length >= 1 ? siteDoc.data().lastOwner : []
            //         if(siteDoc.data().owned && siteDoc.data().owned.uid) {
            //           if(!arrayLastOwner.includes(siteDoc.data().owned.uid)) {
            //             arrayLastOwner.push(siteDoc.data().owned.uid)
            //           }
            //         }

            //         if(arrayLastOwner.length >= 1) {
            //           db.collection('sites').doc(this.keySite).update({
            //             owned: userDoc.data(),
            //             lastOwner: arrayLastOwner
            //           })
            //         } else {
            //           db.collection('sites').doc(this.keySite).update({
            //             owned: userDoc.data()
            //           })
            //         }

            //         if(this.keySite) {
            //           var URLactual = new URL(siteDoc.data().url);
            //           var URLprotocol = URLactual.protocol;
            //           var URLdomain = URLactual.host;
            //           var pathname = URLactual.pathname;
            //           var concatURL = URLprotocol+'//'+ (pathname === '/' ? URLdomain : URLdomain)

            //           var urlWWWHTTP = ''
            //           var urlWWWHTTPS = ''
            //           var urlWWWHTTPpath = ''
            //           var urlWWWHTTPSpath = ''
            //           var urlNoneWWWHTTP = ''
            //           var urlNoneWWWHTTPS = ''
            //           var urlNoneWWWHTTPSpath = ''
            //           var urlNoneWWWHTTPpath = ''
            //           var URLwww = ''
            //           var URLwithoutWWW = ''
            //           if(URLdomain.includes('www.')) {
            //             urlWWWHTTP = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//' + URLdomain
            //             urlWWWHTTPS = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//' + URLdomain
            //             urlWWWHTTPpath = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//' + URLdomain + '/'
            //             urlWWWHTTPSpath = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//' + URLdomain + '/'
            //             urlNoneWWWHTTP = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//' + URLdomain.slice(4)
            //             urlNoneWWWHTTPS = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//' + URLdomain.slice(4)
            //             urlNoneWWWHTTPpath = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//' + URLdomain.slice(4) + '/'
            //             urlNoneWWWHTTPSpath = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//' + URLdomain.slice(4) + '/'
            //             URLwww = URLdomain
            //             URLwithoutWWW = URLdomain.slice(4)
            //           } else {
            //             urlWWWHTTP = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//www.' + URLdomain
            //             urlWWWHTTPS = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//www.' + URLdomain
            //             urlWWWHTTPpath = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//www.' + URLdomain + '/'
            //             urlWWWHTTPSpath = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//www.' + URLdomain + '/'
            //             urlNoneWWWHTTP = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//' + URLdomain
            //             urlNoneWWWHTTPS = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//' + URLdomain
            //             urlNoneWWWHTTPpath = (URLprotocol === 'https:' ? 'https:' : 'http:') + '//' + URLdomain + '/'
            //             urlNoneWWWHTTPSpath = (URLprotocol === 'https:' ? URLprotocol : 'https:') + '//' + URLdomain + '/'
            //             URLwww = 'www.'+URLdomain
            //             URLwithoutWWW = URLdomain
            //           }

            //           db.collection('masterlist').where('url', 'in', [concatURL,URLdomain,URLwww,URLwithoutWWW]).get()
            //           .then(queryMasterlist => {
            //             if(queryMasterlist.size >= 1) {
            //               // site exist in masterlist, only verify new owner
            //               // if(this.verifySite) {
            //                 // update sites related with the url masterlist
            //                 db.collection('sites').where('domain', 'in', [siteDoc.data().url,URLdomain,urlWWWHTTP,urlWWWHTTPS,urlWWWHTTPpath,urlWWWHTTPSpath,urlNoneWWWHTTP,urlNoneWWWHTTPS,urlNoneWWWHTTPpath,urlNoneWWWHTTPSpath]).get()
            //                 .then(querySites => {
            //                   if(querySites.size >= 1) {
            //                     querySites.forEach(siteDoc => {
            //                       // var arrayLastOwner = siteDoc.data().lastOwner && siteDoc.data().lastOwner.length >= 1 ? siteDoc.data().lastOwner : []
            //                       // if(siteDoc.data().owned && siteDoc.data().owned.uid) {
            //                       //   if(!arrayLastOwner.includes(siteDoc.data().owned.uid)) {
            //                       //     arrayLastOwner.push(siteDoc.data().owned.uid)
            //                       //   }
            //                       // }

            //                       if(arrayLastOwner.length >= 1) {
            //                         db.collection('sites').doc(siteDoc.id).update({
            //                           owned: userDoc.data(),
            //                           lastOwner: arrayLastOwner
            //                         })
                                    
            //                         // update data owners masterlist
            //                         queryMasterlist.forEach(siteMasterlist => {
            //                           db.collection('masterlist').doc(siteMasterlist.id).update({
            //                             owned: userDoc.data(),
            //                             lastOwner: arrayLastOwner
            //                           })
            //                         })
            //                       } else {
            //                         db.collection('sites').doc(siteDoc.id).update({
            //                           owned: userDoc.data(),
            //                         })

            //                         // update data owners masterlist
            //                         queryMasterlist.forEach(siteMasterlist => {
            //                           db.collection('masterlist').doc(siteMasterlist.id).update({
            //                             owned: userDoc.data(),
            //                           })
            //                         })
            //                       }
            //                     })
            //                   }
            //                 })
            //               // }
            //             }
            //           })
            //             // })
            //           // })
            //         }

            //         db.collection('Users').doc(userDoc.data().uid).update({
            //           updated: Date.now(),
            //         })
            //       })
            //     })
            //   }
            // }

            const user = auth.currentUser
            if(user) {
              db.collection('Users').doc(user.uid).get()
              .then(userDoc => {
                var userData = {}
                userData.uid = userDoc.id
                userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
                userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
                userData.status = userDoc.data().status ? userDoc.data().status : ''
                userData.email = userDoc.data().email ? userDoc.data().email : ''
                db.collection('Projects').doc(this.siteProp.projectid).collection('sites').doc(this.siteProp.key).update({
                  owner: userData,
                  claimed: true,
                  owners: firebase.firestore.FieldValue.arrayUnion(userDoc.data().uid)
                })
                .then(() => {
                  db.collection('Sites').doc(this.siteProp.key).get()
                  .then(docSite => {
                    // edit and update fixes
                    dbErrors.collection('fixes').where('SiteID', '==', docSite.id).get()
                    .then(docPageFixCollection => {
                      dbErrors.collection('fixes_cache').where('SiteID', '==', docSite.id).limit(1).get()
                      .then(docPageFixCacheCollection => {
                        // console.log(docPageFixCollection.size,docPageFixCacheCollection.size)
                        if(docPageFixCollection.size) {
                          if(docPageFixCacheCollection.size) {
                            var arrayFixes = []
                            docPageFixCollection.forEach(docFix => {
                              if(docFix.data().changes && docFix.data().changes.length) {
                                var filterFixesOwner = docFix.data().changes.filter(item => item.user === userDoc.id)
                                if(filterFixesOwner.length) {
                                  // order by the last fix
                                  filterFixesOwner.sort((a, b) => b.idfix - a.idfix)
                                  filterFixesOwner.forEach(element => {
                                    var filterchangesFixesCache = arrayFixes.filter(ele => ele.id === element.id && ele.idError === element.idError && ele.url === element.url)
                                    if(filterchangesFixesCache.length === 0) {
                                      if(element.idError === 8 || element.idError === 14 || element.idError === 3) {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          urlImg: element.urlImg,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      } else {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })

                            docPageFixCacheCollection.forEach(docFixCache => {
                              dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                changes: arrayFixes,
                                owner: userDoc.id,
                                ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                                updated: Date.now()
                              })
                              .then(() => {
                                console.log('update fixes owner')
                                if(!this.claimLicense && arrayFixes.length >= 1) {
                                  localStorage.setItem('configRunLicenseOwner', true)
                                }
                              })
                              .catch((err) => console.log('err fixes owner', err.message))
                            })
                          } else {
                            dbErrors.collection('fixes_cache').add({
                              SiteID: docSite.id,
                              owner: userDoc.id,
                              ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                              changes: [],
                              updated: Date.now()
                            })
                          }
                        } else {
                          if(docPageFixCacheCollection.size === 0) {
                            dbErrors.collection('fixes_cache').add({
                              SiteID: docSite.id,
                              owner: userDoc.id,
                              ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                              changes: [],
                              updated: Date.now()
                            })
                          } else {
                            var arrayFixes = []
                            
                            docPageFixCacheCollection.forEach(docFixCache => {
                              dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                changes: arrayFixes,
                                owner: userDoc.id,
                                ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                                updated: Date.now()
                              })
                              .then(() => {
                                console.log('update fixes owner')
                                if(!this.claimLicense && arrayFixes.length >= 1) {
                                  localStorage.setItem('configRunLicenseOwner', true)
                                }
                              })
                              .catch((err) => console.log('err fixes owner', err.message))
                            })
                          }
                        }
                      })
                    })

                    var getExternalLinks = docSite.data().externalLinks && docSite.data().externalLinks.length ? docSite.data().externalLinks : []
                    var newExternalLinks = []
                    var counterExternal = 0
                    getExternalLinks.forEach(eleExternal => {
                      counterExternal += 1
                      if(counterExternal <= 50) {
                        newExternalLinks.push(eleExternal)
                      }
                    })

                    db.collection('Sites').doc(this.siteProp.key).update({
                      externalLinks: newExternalLinks,
                      owner: userData,
                      claimed: true,
                      owners: firebase.firestore.FieldValue.arrayUnion(userDoc.data().uid)
                    })
                    localStorage.setItem('changeInSite', true)

                    if(this.claimLicense) {
                      localStorage.setItem('configRunLicenseOwner', true)
                    }

                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Site verified!',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                      },
                    })
                    // }
                  })
                })
              })
            }
          }, 10000)
        }
      })
      .catch(e => {
        this.counterTriesVerify >= 3 ? this.counterTriesVerify = 1 : this.counterTriesVerify += 1
        this.verifying = false
        this.processVerificationFail = true
        console.log(e.message)
      })
    },
    copyTestingCode1() {
      // Ejemplo de uso
      const textoACopiar = `<script src="https://cdn.seocloud.ai/${this.keyID}" type="text/javascript"><\/script>`
      // copiarAlPortapapeles(textoACopiar)

      // function copiarAlPortapapeles(texto) {
        // Crea un elemento de texto temporal
        const tempInput = document.createElement("textarea");
        // Asigna el contenido HTML del script al elemento temporal
        tempInput.value = textoACopiar;
        // Agrega el script al DOM
        document.body.appendChild(tempInput);
        // Agrega el elemento temporal al DOM
        // document.body.appendChild(elementoTemporal);
        // Selecciona el contenido del elemento temporal
        tempInput.select();
        // Intenta copiar el contenido seleccionado al portapapeles
        // document.execCommand("copy");

        // try {
          const successful = document.execCommand('copy')
          const msg = successful ? 'Key copied' : 'unsuccessful'
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: msg,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        // } catch (err) {
        //   alert('Oops, unable to copy')
        // }
        
        // Elimina el elemento temporal del DOM
        document.body.removeChild(tempInput);
      // }

      // const testingCodeToCopy = document.querySelector('#testing-code1')
      // testingCodeToCopy.setAttribute('type', 'text')
      // testingCodeToCopy.select()
      // // console.log(testingCodeToCopy)

      // try {
      //   const successful = document.execCommand('copy')
      //   const msg = successful ? 'Key copied' : 'unsuccessful'
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: msg,
      //       icon: 'CoffeeIcon',
      //       variant: 'success',
      //     },
      //   })
      // } catch (err) {
      //   alert('Oops, unable to copy')
      // }

      // /* unselect the range */
      // testingCodeToCopy.setAttribute('type', 'hidden')
      // window.getSelection().removeAllRanges()
    },
    copyTestingCode2() {
      // Ejemplo de uso
      // console.log(this.keyID)
      const textoACopiar = this.keyID
      // copiarAlPortapapeles(textoACopiar)

      // function copiarAlPortapapeles(texto) {
        // Crea un elemento de texto temporal
        const elementoTemporal = document.createElement("textarea");
        // Asigna el texto al elemento temporal
        elementoTemporal.value = textoACopiar;
        // Asegura que el elemento no sea visible
        elementoTemporal.style.position = "absolute";
        elementoTemporal.style.left = "-9999px";
        // Agrega el elemento temporal al DOM
        document.body.appendChild(elementoTemporal);
        // Selecciona el contenido del elemento temporal
        elementoTemporal.select();
        // Intenta copiar el contenido seleccionado al portapapeles
        // document.execCommand("copy");

        // try {
          const successful = document.execCommand('copy')
          const msg = successful ? 'Key copied' : 'unsuccessful'
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: msg,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        // } catch (err) {
        //   alert('Oops, unable to copy')
        // }
        
        // Elimina el elemento temporal del DOM
        document.body.removeChild(elementoTemporal);
      // }

      // const testingCodeToCopy = document.querySelector('#testing-code2')
      // testingCodeToCopy.setAttribute('type', 'text')
      // testingCodeToCopy.select()
      // // console.log(testingCodeToCopy)

      // try {
      //   const successful = document.execCommand('copy')
      //   const msg = successful ? 'Key copied' : 'unsuccessful'
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: msg,
      //       icon: 'CoffeeIcon',
      //       variant: 'success',
      //     },
      //   })
      // } catch (err) {
      //   alert('Oops, unable to copy')
      // }

      // /* unselect the range */
      // testingCodeToCopy.setAttribute('type', 'hidden')
      // window.getSelection().removeAllRanges()
    },
  },
  created() {
    this.counterTriesVerify = 0
    this.processVerificationFail = false
    this.processVerificationSuccess = false
  }
}
</script>

<style>
.type-active {
    background: #7838FF16;
    /* border: 1px solid #7838FF; */
    border: 1px solid #7838FF;
    border-radius: 10px;
}
.type-inactive {
    background: #ffffff;
    /* border: 1px solid #7838FF; */
    border: 1px solid #DCDCF2;
    border-radius: 10px;
}
.eyeBtn {
    color: rgb(198, 198, 198)
}
.eyeBtn:hover {
    color: #A7AAB9 !important;
}
.link_claim:hover {
  color: #a7aab9 !important;
}
.refreshIcon {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  width: 18px;
  height: 20px;
  /* margin:-60px 0 0 -60px; */
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

@media (min-width: 769px) {
  .card-config-claim {
    width: 195px !important;
    max-width: 195px !important;
    min-width: 195px !important;
  }
  .row-first-step-claim-btns {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media (max-width: 768px) {
  .card-config-claim {
    width: 120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
  }
  .row-first-step-claim-btns {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .row-first-step-claim {
    margin-left: 10px !important;
  }
  .wizard-form-control-mobile-script {
    max-height: 140px !important;
    height: auto !important;
  }
}
</style>